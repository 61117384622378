<template>
  <div class="detailedDown" ref="detailCont">
    <div class="detailedDown-head">
      <img class="headImg" :src="`${$store.state.aliBaseUrl}/images/202202/headImg_1644386202200.png`" alt="">
      <img class="logo" src="../../assets/downLoad/logo.png" alt="">
    </div>
    <div class="down-area">
      <img src="../../assets/downLoad/mainText.png" alt="">
      <!-- <img @click="downEvent" class="downNow" src="../../assets/downLoad/downNow.png" alt=""> -->
      <!-- <img @click="openApp" src="../../assets/downLoad/openApp.png" alt=""> -->
      <!-- <img src="../../assets/downLoad/toBottom.png" alt=""> -->
    </div>
    <div class="det-item" v-for="(item, index) in imgData" :key="index">
      <img :src="item" alt="">
      <!-- <img src="../../assets/downLoad/toBottom.png" v-if="index<imgData.length-1" alt="">
      <img class="toTop" @click="toTopEvent" src="../../assets/downLoad/toTop.png" v-else alt=""> -->
    </div>
    <pagetips ref="pagetips"></pagetips>
  </div>
</template>

<script>
import pagetips from '../commonModule/pagetips'
export default {
  name: 'detailedDown',
  components: {
    pagetips
  },
  data () {
    return {
      imgData: [],
      downurls: '',
      type: 0
    }
  },
  // watch: {
  //   '$store.state.deviceType'(val) {
  //     alert(val);
  //     let type = val == 1 ? 2 : 1;
  //     this.getNewVersionEvent(type);
  //   }
  // },
  mounted () {
    const type = this.$store.state.deviceType == 1 ? 2 : 1
    this.type = this.$store.state.deviceType
    if (this.$store.state.deviceType < 4) {
      this.getNewVersionEvent(type)
    } else {
      this.downurls = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.czh.zhxt'
      // this.$refs.pagetips.showEvent();
    }
  },
  methods: {
    getNewVersionEvent (type) {
      this.$request.getNewVersion({ type }).then(res => {
        if (res.data.code == 0) {
          this.downurls = res.data.data.downurls
        }
      })
    },
    downEvent () {
      if (!this.downurls) {
        this.Info('暂时无法下载')
        return
      }
      // window.location.href = this.downurls;
      window.location.replace(this.downurls)
    },
    toTopEvent () {
      document.querySelector('.detailedDown').scrollTop = 0
    },
    openApp () {
      console.log(this.type)
      if (this.type == 1) {
        window.location.href = 'com.czh.zhxt.app://'
      } else if (this.type == 2) {
        window.location.href = 'android://com.czh.zhxt/open'
      } else if (this.type == 4 || this.type == 5 || this.type == 6 || this.type == 7) {
        // this.Info('选择浏览器打开该页面,再进行app打开');
        this.$refs.pagetips.showEvent()
      }
    }
  }
}
</script>

<style lang='scss'>
  .detailedDown {
    height: 100%;
    overflow: scroll;
    .detailedDown-head {
      width: 100%;
      position: relative;
      .headImg {
        width: 100%;
      }
      .logo {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 36px);
        width: 100px;
        height: 116px;
      }
    }
    .down-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
      & > img:nth-of-type(1) {
        width: 156px;
        height: 48px;
      }
      & > img:nth-of-type(2) {
        width: 252px;
        height: 51px;
        margin: 27px 0 19px;
      }
      & > img:nth-of-type(3) {
        width: 94px;
        height: 12px;
      }
      & > img:nth-of-type(4) {
        width: 33px;
        height: 16px;
        margin: 19px 0 22px;
      }
      .downNow {
        animation: btnmove 2s forwards;
        -webkit-animation: btnmove 2s forwards; /* Safari and Chrome */
      }
    }
    .det-item {
      text-align: center;
      margin-top: 22px;
      transform: translateX(-50%);
      opacity: 0;
      animation: myfirst 1s forwards;
      -webkit-animation: myfirst 1s forwards; /* Safari and Chrome */
      & > img:nth-of-type(1) {
        width: 100%;
      }
      & > img:nth-of-type(2) {
        width: 33px;
        height: 16px;
        margin-top: 24px;
      }
      .toTop {
        width: 33px!important;
        height: 36px!important;
      }
    }
    .det-item:nth-of-type(3) {
      animation-delay: 0.5s;
	    -webkit-animation-delay: 0.5s;
    }
    .det-item:nth-of-type(4) {
      animation-delay: 1s;
	    -webkit-animation-delay: 1s;
    }
    .det-item:nth-of-type(5) {
      animation-delay: 1.5s;
	    -webkit-animation-delay: 1.5s;
    }
    .det-item:nth-of-type(6) {
      animation-delay: 2s;
	    -webkit-animation-delay: 2s;
    }
    .det-item:nth-of-type(7) {
      animation-delay: 2.5s;
	    -webkit-animation-delay: 2.5s;
      margin-bottom: 44px;
    }
    @keyframes btnmove
    {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes btnmove
    {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes myfirst
    {
      0% {
        opacity: 0;
        transform: translateX(-50%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @-webkit-keyframes myfirst
    {
      0% {
        opacity: 0;
        transform: translateX(-50%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
</style>
